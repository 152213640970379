import { css } from 'astroturf';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { ArrowLeft } from 'react-bytesize-icons';

import CTA from '../components/CTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .arrow {
    vertical-align: text-bottom;
    transform: translateY(-2px);
  }

  .pt {
    text-align: center;
    max-width: 32rem;
    margin: 4rem auto 6rem;
    padding: 0 2rem;

    & p {
      opacity: 0.75;
      margin-bottom: 2rem;
    }
  }
`;
export default class PostTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.mdx;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    let title = 'What';
    if (post.plural) {
      title += ` are ${post.title}?`;
    } else if (post.determiner) {
      title += ` is a ${post.title}?`;
    } else {
      title += ` is ${post.title}?`;
    }

    return (
      <Layout>
        <SEO
          title={title}
          keywords={['']}
          description={postNode.excerpt}
          pathname={slug}
          ogFileName={postNode.fields.ogFileName}
        />
        <PageHeader>
          <h1>
            {title}
          </h1>
          <p>
            From the <Link to="/responsive-design-glossary/">Responsive Design Glossary</Link>
          </p>
        </PageHeader>
        <article className="widePage">
          <div className="childPadding">
            <MDXRenderer>{postNode.body}</MDXRenderer>
          </div>
        </article>

        <div className={styles.pt}>
          <p>
            The responsive design glossary explains the many terms used in responsive design and development. It's a
            practical reference for anyone getting started with responsive design and development.
          </p>
          <Link to="/responsive-design-glossary/">
            <ArrowLeft className={styles.arrow} height={16} /> Get an overview of all responsive design terms
          </Link>
        </div>
        <CTA />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TermsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt(pruneLength: 120)
      frontmatter {
        title
        plural
        determiner
      }
      fields {
        slug
        ogFileName
      }
    }
  }
`;
